<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

const routes = useRouter()

function goToUnsupportedPayees() {
  modal.hide()
  routes.push({
    name: 'bank-file-upload',
  })
}
</script>

<template>
  <base-mdl title="Review Payments" width="sm" layout="v2">
    <div class="-mx-8 border-y px-8">
      <div class="my-5">
        <p>
          We regret to inform you that one or more payees in your ABA file are
          causing an issue. To ensure the smooth processing of your batch
          payment, please take the following actions:
        </p>
        <ol class="ml-5 mt-8 list-decimal">
          <li>
            Review Payments: Use the button below to view the payees causing the
            problem.
          </li>
          <li>
            Take suggested actions: Follow the suggested steps on the following
            page to resolve the identified issues.
          </li>
        </ol>
      </div>
    </div>
    <div class="w-full pt-5">
      <base-button full-width @click="goToUnsupportedPayees">
        Review Payments
      </base-button>
    </div>
  </base-mdl>
</template>
